import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import DotLoader from "react-spinners/DotLoader";

import ProjectCard from "./ProjectCard";
import Footer from "../Footer";
import "./project.css";

function Projects() {
  const [projects, setProject] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(process.env.REACT_APP_SERVER_ENDPOINT + "/projects", { mode: "cors" })
      .then((response) => response.json())
      .then((d) => {
        console.log(d);
        setProject(d);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }, []);

  // Function to render projects
  const renderProjects = (projects) => {
    return projects.map((project, index) => {
      const isLastProject = index === projects.length - 1;
      const isOddNumberOfProjects = projects.length % 2 !== 0;
      return (
        <Col
          m
          xl={isLastProject && isOddNumberOfProjects ? 12 : 6}
          key={project._id}
        >
          <ProjectCard project={project} />
        </Col>
      );
    });
  };

  return (
    <div>
      <DotLoader
        color="#f0ece5"
        loading={isLoading}
        size={50}
        cssOverride={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      />
      {projects && (
        <Container id="projects" className="sections">
          <Row className="p-5 justify-content-md-center">
            <h2 className="secondaryHeader">Projects</h2>
            {renderProjects(projects)}
          </Row>
        </Container>
      )}

      <Footer />
    </div>
  );
}

export default Projects;
