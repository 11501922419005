import { Container, Row } from "react-bootstrap";
import { IoLogoJavascript } from "react-icons/io5";
import {
  SiExpress,
  SiMysql,
  SiMongodb,
  SiMicrosoftsqlserver,
  SiOracle,
} from "react-icons/si";
import { BiLogoPostgresql } from "react-icons/bi";
import {
  FaPython,
  FaJava,
  FaReact,
  FaBootstrap,
  FaHtml5,
  FaCss3Alt,
  FaNode,
} from "react-icons/fa";

import "./homepage.css";

function AboutMe() {
  return (
    <Container className="lightSection sections" id="aboutMe">
      <Row className="p-5">
        <h2 className="secondaryHeader">About me</h2>
        <p>
          My name is Tony Klinpon. I am a computer programming student at Humber
          college. I have some specialty in front-end developing using ReactJS
          and would love to explore in the back-end programming as well. I also
          have professional experience on data warehouse and ETL management.
        </p>
        <div className="icon-container">
          <FaPython className="icon" title="Python" />
          <FaJava className="icon" title="Java" />
          <span>|</span>
          <FaHtml5 className="icon" title="HTML" />
          <FaCss3Alt className="icon" title="CSS" />
          <IoLogoJavascript className="icon" title="JavaScript" />
          <span>|</span>
          <FaReact className="icon" title="React" />
          <FaBootstrap className="icon" title="Bootstrap" />
          <span>|</span>
          <FaNode className="icon" title="NodeJS" />
          <SiExpress className="icon" title="ExpressJS" />
          <span>|</span>
          <BiLogoPostgresql className="icon" title="PostgreSQL" />
          <SiMysql className="icon" title="MySQL" />
          <SiMicrosoftsqlserver className="icon" title="SQL Server" />
          <SiOracle className="icon" title="Oracle" />
          <SiMongodb className="icon" title="MongoDB" />
        </div>
      </Row>
      <Row></Row>
    </Container>
  );
}

export default AboutMe;
