function Footer(props) {
  const className = props.light ? "lightSection" : null;
  return (
    <footer className={className}>
      <p style={{ marginBottom: 0, paddingBottom: "10px" }}>
        &copy; 2024 Thiti Klinpon
      </p>
    </footer>
  );
}

export default Footer;
