import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import DotLoader from "react-spinners/DotLoader";

import Footer from "../Footer";
import "./education.css";

function Education(props) {
  const [edu, setEdu] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetch(process.env.REACT_APP_SERVER_ENDPOINT + "/education", {
      mode: "cors",
    })
      .then((response) => response.json())
      .then((d) => {
        setEdu(d);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }, []);

  const renderEducation = (edus) => {
    return edus.map((edu) => {
      return (
        <Card text="light" key={edu._id}>
          <Card.Body>
            {" "}
            <Row>
              <Col md={3} className="image-container">
                <a href={edu.url}>
                  <img
                    src={edu.logo}
                    className="edu-img"
                    alt="education logo"
                  />
                </a>
              </Col>
              <Col md={9} className="edu-content">
                <Card.Title>
                  {edu.level} of {edu.program}
                </Card.Title>
                <Card.Subtitle className="mb-2">{edu.college}</Card.Subtitle>
                <Card.Subtitle className="mb-2">
                  {edu.startDate} - {edu.endDate}
                </Card.Subtitle>
                <p className="mb-2">{edu.location}</p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      );
    });
  };

  return (
    <div>
      <DotLoader
        color="#f0ece5"
        loading={isLoading}
        size={50}
        cssOverride={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      />
      {edu && (
        <Container id="education" className="sections">
          <Row className="p-5">
            <h2 className="secondaryHeader">Education</h2>
            {renderEducation(edu)}
          </Row>
        </Container>
      )}

      <Footer />
    </div>
  );
}

export default Education;
