import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Routes, Route, NavLink } from "react-router-dom";

import Home from "./home/Home";
import Education from "./education/Education";
import Experiences from "./experience/Experiences";
import Projects from "./project/Projects";
import Contact from "./contact/Contact";

function MainNavBar() {
  return (
    <div>
      <Navbar expand="md" data-bs-theme="dark" className="mainNavBar">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="m-auto"
              defaultActiveKey="#home"
              variant="underline"
            >
              <Nav.Link as={NavLink} to="/" style={{ "--i": 1 }}>
                Home
              </Nav.Link>
              <Nav.Link as={NavLink} to="/projects" style={{ "--i": 2 }}>
                Projects
              </Nav.Link>
              <Nav.Link as={NavLink} to="/education" style={{ "--i": 3 }}>
                Education
              </Nav.Link>
              <Nav.Link as={NavLink} to="/expiriences" style={{ "--i": 4 }}>
                Experiences
              </Nav.Link>
              <Nav.Link as={NavLink} to="/contact" style={{ "--i": 5 }}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/education" element={<Education />} />
        <Route path="/expiriences" element={<Experiences />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default MainNavBar;
