import { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { TypeAnimation } from "react-type-animation";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import DotLoader from "react-spinners/DotLoader";

import AboutMe from "./AboutMe";
import Footer from "../Footer";

import "./homepage.css";

function Home() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(process.env.REACT_APP_SERVER_ENDPOINT, { mode: "cors" })
      .then((response) => response.json())
      .then((d) => {
        setData(d.urls);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }, []);
  return (
    <div>
      <DotLoader
        color="#f0ece5"
        loading={isLoading}
        size={50}
        cssOverride={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      />
      {data && (
        <Container id="home" className="sections">
          <Row className="p-4 align-items-center">
            <Col md={6} lg={5} className="p-2">
              <img
                id="mainImg"
                src={data && data.find((url) => url.name === "profile").url}
                alt="main profile pic"
              />
            </Col>
            <Col md={6} lg={7} className="p-4">
              <h1 id="mainHeader">
                Hi, I'm <span className="highlight">Tony</span>
              </h1>
              {/* <h2 className="secondaryHeader">I'm a Software Developer</h2> */}
              <h2 className="secondaryHeader">I'm a </h2>
              <TypeAnimation
                sequence={[
                  "Student",
                  1000,
                  "Photographer",
                  1000,
                  "Developer",
                  1000,
                ]}
                wrapper="span"
                speed={50}
                className="secondaryHeader highlight"
              />
              <div className="centerButton">
                <Button
                  variant="light"
                  href={data && data.find((url) => url.name === "resume").url}
                  download="Resume_Tony"
                  target="_blank"
                >
                  Resume
                </Button>
                <Button variant="secondary" href={"/projects"}>
                  Projects
                </Button>
              </div>
              <div className="link-container">
                <a
                  href={data.find((url) => url.name === "linkedin").url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin className="icon" />
                </a>

                <a
                  href={data.find((url) => url.name === "github").url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithub className="icon" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      )}
      {data && <AboutMe />}
      <Footer light={true} />
    </div>
  );
}

export default Home;
